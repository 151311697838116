<template>
  <div class="service">
    <!-- 广告ban -->
    <!-- <div class="kf_ban" :style="note"></div>
    <FromList v-model="search">
    </FromList> -->
	<!--轮播图-->
	<home-swiper> </home-swiper>
    <!-- 专项 -->
    <section class="industry_custom">
      <div class="w">
        <!--  专项 -->
        <div class="pea-title">
          <div class="one-content">
            <div class="copy-title">
              <div class="copy-con">
                <span></span>
                <h3>{{ special }}</h3>
                <span></span>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <el-row>
            <Intserv></Intserv>
            <el-card class="multiple1 right">
              <div class="left-block">服务分类</div>
              <div class="search">
                <el-input
                  placeholder="请输入内容"
                  prefix-icon="el-icon-search"
                  class="input-style"
                  v-model="input"
                >
                </el-input>
                <span class="search-icon"> 搜索</span>
              </div>
              <div class="service-tabs">
                <div class="tabs-title">服务类型:</div>
                <div class="tabs-list">
                  <div
                    class="list-item"
                    v-for="(ite, index) in type"
                    :key="index"
                    :class="index == Index ? 'active' : ''"
                    @click="Type(ite, index)"
                  >
                    {{ ite.name }}
                  </div>
                </div>
              </div>
            </el-card>
          </el-row>
        </div>
      </div>
    </section>
    <div class="w service-list">
      <div class="tabs">
        <span
          class="tabs-one"
          :class="idx == index ? 'active' : ''"
          @click="Csort(i, idx)"
          v-for="(i, idx) in sort"
          :key="idx"
          >{{ i }}</span
        >
      </div>
      <div class="item-nav">
        <ul v-if="items.length !== 0">
          <li class="items" v-for="(i, idx) in items" :key="idx"   @click="xq(i.id)">
            <img
              alt=""
              :src="urlImg+i.img"
              class="items-image"
              lazy="loaded"
            />
            <p class="items-price">{{ i.cost }}元</p>
            <p class="items-info">{{ i.title }}</p>
          </li>
          <!-- 分页 -->
          <div class="pagination">
            <pagination
              class="pages"
              v-show="total > 0"
              :total="total"
              layout="prev, pager, next"
              :page.sync="listQuery.page"
              :limit.sync="listQuery.limit"
              @pagination="ServerKnowledge"
            />
          </div>
        </ul>
        <!---->
        <div v-else>
          <div class="fruless">
            <div class="fruless-comtain">
              <img
                src="../../../assets/img/qiyeyun/no.png"
                alt="暂无内容"
                class="fruless-image"
              />
              <div class="fruitless-tip">暂无内容</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	import HomeSwiper from "../../../components/common/Swiper";
import { ServerKnowledge, Type } from "@/common/js/api";
import Pagination from "@/common/Pagination";
 import FromList from "../../../common/input";
import Intserv from "../../../common/intserv";
import { urlImg } from "@/common/js/url";

export default {
  name: "SpecialNel",
  components: {
    FromList,
	HomeSwiper,
    Intserv,
    Pagination,
  },
  data() {
    return {
       urlImg: "",
      s: "", //判断从哪个页面进
      special: "", //标题
      Index: -1,
      search: "",
      input:'',
      type: [],
      note: {
        backgroundImage:
          "url(" + require("../../../assets/img/qiyeyun/zscq.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      }, // 背景图
      index: 0,
      sort: ["综合", "热门优先", "价格升序"],
      items: [],
      total: 0,
      listQuery: {
        limit: 6,
        page: 1,
      },
      typeId: "",
      all: "",
      views: "",
      money: "",
    };
  },
  created() {
    this.urlImg = urlImg();
    //判断从哪个页面进
    this.s = this.$route.query.s;

    if (this.s === "zscq") {
      this.note.backgroundImage =
        "url(" + require("../../../assets/img/qiyeyun/zscq.png") + ")";
      this.special = "知识产权专题";
      this.Index = 1;
      this.typeId = 113;
    } else if (this.s === "zcyy") {
      this.note.backgroundImage =
        "url(" + require("../../../assets/img/qiyeyun/zcyy.png") + ")";
      this.special = "政策应用专题";
      this.Index =9;
      this.typeId = 142;
    } else if (this.s === "kjjr") {
      this.note.backgroundImage =
        "url(" + require("../../../assets/img/qiyeyun/kjjr.png") + ")";
      this.special = "科技金融专题";
      this.Index = 0;
      this.typeId = 112;
    } else if (this.s === "kjfw") {
      this.note.backgroundImage =
        "url(" + require("../../../assets/img/qiyeyun/kjjr.png") + ")";
      this.special = "科技服务专题";
      this.Index =8;
      this.typeId = 141;
    } else if (this.s === "zqzx") {
      this.note.backgroundImage =
        "url(" + require("../../../assets/img/qiyeyun/zqzx.png") + ")";
      this.special = "全过程咨询专题";
      this.Index = 7;
      this.typeId = 123;
    } else if (this.s === "rclx") {
      this.note.backgroundImage =
        "url(" + require("../../../assets/img/qiyeyun/rclx.png") + ")";
      this.special = "技术人才专题";
      this.Index = 6;
      this.typeId = 122;
    }
    else if (this.s === "jszy") {
      this.note.backgroundImage =
        "url(" + require("../../../assets/img/qiyeyun/zqzx.png") + ")";
      this.special = "技术转移专题";
      this.Index = 8;
      this.typeId = 141;
    }
    this.getType();
    this.ServerKnowledge();
  },
  methods: {
    // 列表
    ServerKnowledge() {
      ServerKnowledge({
        all: this.all,
        views: this.views,
        money: this.money,
        type: this.typeId,
        page: this.listQuery.page,
        limits: this.listQuery.limit,
      })
        .then((res) => {
          console.log(res);
          this.items = res.data.data;
          this.total = res.data.total;
        })
        .catch(() => {});
    },
  
    // 服务分类
    Type(item, idx) {
      this.Index = idx;
      this.typeId = item.id;
      this.ServerKnowledge();
    },
    // 排序
    Csort(item, idx) {
      this.index = idx;
      if (this.index == 0) {
        this.all = 1;
        this.views = "";
        this.money = "";
      } else if (this.index == 1) {
        this.all = "";
        this.views = 3;
        this.money = "";
      } else if (this.index == 2) {
        this.all = "";
        this.views = "";
        this.money = 2;
      }
      this.ServerKnowledge();
    },
    // 类型
    getType() {
      Type()
        .then((res) => {
          console.log(res);
          this.type = res.data;
        })
        .catch(() => {});
    },
    // 进入详情页
    xq(id) {
      this.$router.push({
        path: "/s_xq",
        query: {
          id: id,
        },
      });
    },
  },
  mounted() {},
};
</script>

<style scoped lang="less">
.service {
  background-color: rgba(248, 249, 250, 100);
  .search-content {
    top: 250px;
  }
}

.multiple1 {
  width: 964px;
  height: 380px;
}
// 服务展示

.left-block {
  z-index: 2;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 5px 5px 0px 0px;
  background-color: rgba(56, 146, 235, 100);
  color: rgba(248, 249, 250, 100);
  font-size: 20px;
}
/deep/.el-card__body {
  padding: 0;
}
// 服务分类
.search {
  display: flex;
  margin-top: 20px;
  padding: 50px 40px 63px;
  height: 40px;
  align-items: center;
  /deep/.el-input__inner {
    background: #f7f7f7;
    border: 0;
    border-radius: 20px;
  }
}
.search .input-style {
  display: inline-block;
  width: 808px;
  border-radius: 20px;
  height: 40px;
  padding-left: 12px;
  background: #f7f7f7;
}

.search .search-icon {
  display: flex;
  width: 100px;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: #0084ff;
  cursor: pointer;
}
.service-tabs {
  display: flex;
  padding: 0 48px 40px;
  font-size: 16px;
  background-color: #fff;
}
.service-tabs .tabs-title {
  color: #333;
  width: 88px;
  font-weight: 500;
  margin-top: 5px;
}
.service-tabs .tabs-list {
  width: 808px;
  color: #999;
  .active {
    background-color: #3892eb;
    color: #fff;
    border-radius: 5px;
  }
}

.service-tabs .tabs-list .list-item {
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 20px;
  padding: 6px 14px;
  cursor: pointer;
}

// 综合
.service-list {
  padding: 12px 23px;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  .tabs {
    padding: 19px 34px;
    background-color: #f7f7f7;
    .tabs-one {
      font-size: 18px;
      color: #696969;
      margin-right: 40px;
      cursor: pointer;
    }
    .active {
      color: #3892eb;
    }
  }
  .item-nav {
    margin-top: 30px;
    .items:nth-of-type(3n) {
      margin-right: 0;
    }
    .items:hover {
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
      border-radius: 5px;
    }
    .items {
      display: inline-block;
      width: 348px;
      margin-right: 50px;
      margin-bottom: 20px;
      transition: 0.3s;
      vertical-align: top;
      text-align: center;
      font-size: 18px;
      cursor: pointer;
      .items-image {
        display: inline-block;
        width: 348px;
        height: 214px;
      }
      .items-price {
        font-weight: 500;
        margin-top: 15px;
        color: #ff0707;
      }
      .items-info {
        margin-top: 15px;
        padding-bottom: 8px;
      }
    }
    .fruless {
      background-color: #fff;
      padding: 0 0 100px;
      text-align: center;
      font-size: 20px;
      .fruless-comtain {
        margin: 0 auto;
        .fruless-image {
          display: inline-block;
          width: 530px;
          height: 404px;
        }
        .fruitless-tip {
          font-size: 20px;
          color: #696969;
          text-align: center;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
	.w{
		width: 98%;
	}
	.copy-con h3{
		font-size: 3rem;
	}
	.copy-con{
		align-items: center;
		display: flex;
		
	}
	.multiple{
		width: 100%;
	}
	.multiple ul{
		width: 100%;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
	.left-block{
		line-height: 4rem;
		height: 4rem;
		font-size: 2.3rem;
	}
	/deep/.el-input__inner{
		height: 3rem;
		line-height: 3rem;
		color: #333333;
		font-size: 1.7rem;
	}
	/deep/.el-input{
		font-size: 1.6rem;
	}
	/deep/.el-input__icon{
		display: none;
	}
	.search .search-icon{
		font-size: 1.8rem;
		padding-top: 1.5rem;
	}
	.service-tabs .tabs-title{
		font-size: 1.8rem;
		width: 8rem;
		flex-shrink: 0;
	}
	.service-tabs .tabs-list .list-item{
		font-size: 1.6rem;
	}
	.multiple1{
		width: 100%;
		height: auto;
	}
	.service-list .tabs .tabs-one{
		font-size: 2rem;
	}
	.service-list .item-nav .items{
		width: 49%;
		margin: 0 0 1.5rem 0;
	}
	.service-list .item-nav .items .items-image{
		width: 100%;
		height: 16rem;
	}
	.service-list .item-nav ul{
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	.service-list .item-nav .items .items-price{
		font-size: 1.7rem;
	}
	.service-list .item-nav .items .items-info{
		font-size: 1.4rem;
	}
	.pagination{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}
	
}
</style>